.fdc-device {
  // ::before's padding-top gets calculated into this 100% height
  height: 100%;

  // Trick to get our perfect device ratio at all sizes:
  // ::before padding-top (what becomes the height) set to a ratio of the width
  &::before {
    content: "";
    display: block;
    padding-top: calc(var(--device-height) / var(--device-width) * 100%);
    width: 100%;
  }
}
