.container {
  --award-width: 320;
  min-height: calc(var(--award-width) * 0.0328125rem);
  margin: 0 auto;
}

.top-text {
  font-size: calc(var(--award-width) * 0.004125rem);
  margin: 0 0 0.2em;
}

.bottom-text {
  font-size: calc(var(--award-width) * 0.0046875rem);
  margin: 0.2em 0 0;
  padding: 0 4%;
}

.title {
  font-size: calc(var(--award-width) * 0.0171875rem);
  margin: -0.05em auto -0.2em;
  transform: translateX(-50%) scale(var(--award-title-scale, 1));
}

.leaf {
  right: 101%;
  transform: translateY(-50%);
  &::before {
    border-width: 0 0 0 0.25rem;
    padding-top: calc(139 / 45) * 100%;

    @supports (mask-size: contain) {
      background: currentColor;
      border: 0;
      border-radius: 0;
      // WHY: Safari doesn't support SVGs in CSS mask (crossorgin issue)
      mask: url('data:image/svg+xml,%3Csvg viewBox="0 0 45 139" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M30 0a28.3 28.3 0 0 0-4 10c4-2.1 5.8-5.6 4-10zm-8 4c-1.4 4-2 6.8-1 11h2c1-4 1.6-7.6-1-11zm9 5a16 16 0 0 0-6 8c3.4-1.6 6.5-4 6-8zm-14 3a20.2 20.2 0 0 0 0 12h2c1.1-4.6 1.4-8.4-2-12zm6 4l-1 4c1.1-1.6 1.4-2 1-4zm5 2c-2.6 2.2-4 3.7-5 7 3.1-1.5 5.8-3.3 5-7zm-16 2v12c-1.7-2-2.7-2.9-5-4 0 6.5.6 11.8 6 16 1-4.3.2-7.8-1-12a18 18 0 0 0 4 2c0-5.5.4-10.1-4-14zm9.3 1.7s0 1 .4.6c0 0 0-1-.4-.6zM20 24c-4.3 13-7.5 26.2-8 40-.1 5.3 1 10.6 1 16-2.5-6.6-4.4-11.5-11-15 0 5 1 9.7 4 14 2 2.7 6 5.7 7 1 1.5 4.5 2.4 9.3 3 14-4.2-7.1-7.6-12-16-14 2.2 7.7 8 16.4 17 14 .6 3.8 2 7.3 3 11A25.5 25.5 0 0 0 1 94c1.4 5.5 6 9.7 11 12.2 3.2 1.6 7 3.1 8-1.2 2.3 3.9 4.3 7.9 6 12a27.9 27.9 0 0 0-21-9c4 9.2 14 16 24 13 1.7 3.5 4 6.7 6 10-6.2-5.3-12.8-8-21-6v2c6.9 4.2 14.5 10 22 4 1.3 2.5 2.7 5.7 5.3 7.2 1.4.9 3.5.7 3.5-1.4.1-3.6-4.9-8-6.8-10.8 1.8 1.3 3 2.1 5 3 1.6-8.6 2.1-18.6-7-23-.6 7.3-.5 13 2 20-3.4-4.4-6.6-9-9-14a18 18 0 0 0 4 2c2.6-7.5 3-16.8-5-21-.8 6-.5 11 0 17-6-10.2-8.8-21.5-11-33 1.5 1 1.2 1 3 1a25.2 25.2 0 0 0 3-20c-4.3 3.7-5.7 8.4-6 14-1.3-4.3-1-8.6-1-13 6.7-1.1 9.4-7.8 10-14-4.9 1.7-7.4 5.2-9 10-2-6.6.2-14.4 1.7-21 .7-3.4 2.3-6.6 1.3-10zm0 10c4.7-1.6 7-4.2 8-9-4.5 1.8-7 4.2-8 9zm-2 12c4.9-2.3 7.4-5.6 8-11a12.7 12.7 0 0 0-8 11zM6 40c0 6.5-.5 12.7 6 16 0-6.5-.6-11.8-6-16zM2 51c1 7.3 1.7 14.5 9 18 0-7.8-1.4-14.4-9-18zm21 23c-1.7 7.4-4.5 16.3 2 22 3.2-7.4 4.7-16.2-2-22z" fill="%23F5E8E4"/%3E%3C/svg%3E')
        center no-repeat;
      mask-size: contain;
    }
  }

  &--right {
    left: 101%;
    right: auto;
    transform: translateY(-50%) scaleX(-1);
  }
}
